const { hot } = require("react-hot-loader/root")

// prefer default export if available
const preferDefault = m => (m && m.default) || m


exports.components = {
  "component---cache-dev-404-page-js": hot(preferDefault(require("/app/.cache/dev-404-page.js"))),
  "component---src-templates-imprint-js": hot(preferDefault(require("/app/src/templates/imprint.js"))),
  "component---src-templates-page-js": hot(preferDefault(require("/app/src/templates/page.js")))
}

